<template>
  <div class="management-info-page">
    <bread-crumb
      v-if="modelInfo"
      :name="modelInfo.name"
    />
    <div class="page-title-row">
      <div class="title">
        {{ $t('modelExplanation.modelExplanationTitle') }}
      </div>
    </div>
    <spinner
      v-if="isLoading"
      :title="$t('editing.loading')"
    />
    <template v-else>
      <div class="description">
        {{ $t('modelExplanation.modelExplanationDescription') }}
      </div>
      <div class="info-block">
        <div v-if="modelPredictExplanation">
          <div class="info-block__title">
            {{ $t('modelTraining.featureImportance') }}
            <remind-popover
              :content="$t('modelExplanation.reminder.featureImportance')"
              icon-name="description"
            />
          </div>
          <div class="info-block__content">
            <model-task
              :component-id="modelPredictExplanation.modelPredictExplanationChartId"
              :is-show-toolbox="false"
              :is-show-legend="false"
              :is-warning-enable="false"
              :is-show-label-data="true"
            />
          </div>
        </div>
        <div
          v-else
          class="error-message"
        >
          <svg-icon icon-class="data-explanation" />
          {{ $t('modelExplanation.noModelSupported') }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getModelInfo, getModelExplanation } from '@/API/Model'
import BreadCrumb from './components/BreadCrumb'
import RemindPopover from '@/components/popover/RemindPopover.vue'

export default {
  name: 'ModelExplanation',
  components: {
    BreadCrumb,
    RemindPopover
  },
  data () {
    return {
      isLoading: false,
      modelInfo: null,
      modelPredictExplanation: null,
      isError: false
    }
  },
  computed: {
    modelId () {
      return this.$route.params.model_id
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.isLoading = true
      try {
        this.modelInfo = await getModelInfo(this.modelId)
        this.modelPredictExplanation = await getModelExplanation(this.modelId)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  font-size: 13px;
  margin-bottom: 16px;
  color: #DDDDDD;
}
.error-message {
  font-size: 14px;
  text-align: center;
  color: #A7A7A7;
}
</style>
